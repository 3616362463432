.mobile-app-section {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #652d85;
    color: #fff;

    .content {
        width: 500px;
        h4 {
            font-size: 48px;
            line-height: 140%;
        }
        p {
            font-size: 32px;
        }

        .images {
            margin-top: 20px;
           
            display: flex;
            h6 {
                font-size: 32px;
                width: 90%;
            }

            .images-bottom{
                margin-left: 10px;
                display: flex;
                flex-direction: column;
                gap: 5px;
            }
        }
    }
}
@media only screen and (max-width:800px){
    .mobile-app-section{
        padding: 10px 0;
        flex-direction: column;
        
    }
}