@import "../../variables";

.pagination-container {
    display: flex;
    justify-content: center;
    list-style-type: none;

    .pagination-item {
        padding: 0 12px;
        height: 32px;
        text-align: center;
        margin: auto 4px;
        color: #fff;

        display: flex;
        box-sizing: border-box;
        align-items: center;
        letter-spacing: 0.01071em;
        border-radius: 16px;
        line-height: 1.43;
        font-size: 24px;
        min-width: 32px;

        &.dots {
            color: $primary-color;
        }
        &.dots:hover {
            background-color: transparent;
            cursor: default;
        }
        &:hover {
            background-color: rgba(255, 255, 255, 0.04);
            cursor: pointer;
        }

        &.selected {
            background-color: #fff;
            color: $primary-color;

            border-radius: 16px;
            border: 1px solid #d2d2d2;
        }

        .arrow {
            &::before {
                position: relative;
                /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
                content: "";
                /* By using an em scale, the arrows will size with the font */
                display: inline-block;
                width: 0.4em;
                height: 0.4em;
                border-right: 0.12em solid $primary-color;
                border-top: 0.12em solid $primary-color;
            }

            &.left {
                transform: rotate(-135deg) translate(-50%);
            }

            &.right {
                transform: rotate(45deg);

                ::before {
                    border-right: 0.12em solid #fff;
                    border-top: 0.12em solid #fff;
                }
            }
        }

        .arrow {
            &::before {
                border-right: 0.12em solid #fff;
                border-top: 0.12em solid #fff;
            }
        }
        &.disabled {
            pointer-events: none;

            .arrow::before {
                border-right: 0.12em solid #fff;
                border-top: 0.12em solid #fff;
            }

            &:hover {
                background-color: transparent;
                cursor: default;
            }
        }
    }
}
