@import "../../variables";

.accordion {
    color: $main-color;

	padding: 4px 22px 14px;
	border-bottom: 0.5px solid $primary-color;
    &:nth-child(2n) {
       
        background: #f7f7f7;
    }
    .accordion-wrapper {
        width: 75%;
        margin-top: 24px;
        border: none;
        & + * {
            margin-top: 0.5em;
        }
    }
    @media only screen and (max-width: 1200px) {
        .accordion-wrapper {
            width: 100%;
        }
    }

    .accordion-item {
        overflow: hidden;
        transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
        height: auto;
        max-height: 9999px;
    }

    .accordion-item.collapsed {
        max-height: 0;
        transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
    }

    .accordion-title {
        display: flex;
        align-items: center;
        gap: 45px;
        padding: 0;

        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        /* or 25px */

        font-size: 24px;
        line-height: 140%;

        cursor: pointer;

        &::before {
            content: "+";
            width: 24px;
            height: 24px;
            font-size: 35px;
			color: $primary-color;
        }

        &.open {
            &::before {
                content: "-";
                border-top: 0;
            }
        }
    }

    .accordion-content {
        padding: 24px 0;
        font-size: 18px;
        line-height: 140%;

        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        /* or 25px */
    }
}
