@import "../../variables";

.header {
    // position: fixed;
    // top: 0;

    position: relative;
    z-index: 10;
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 23px;

    z-index: 100;

    .logo {
        margin-right: 100px;
    }
    .nav__links {
        transform: translateY(10px);
        display: flex;


        a::nth-child(1) {
            margin-left: 0px;
        }
        a {
            color: $primary-color;
            cursor: pointer;
            margin-left: 24px;
            border-bottom: 3px solid transparent;

            transition: all 0.3s linear;
            &:hover {
                border-bottom: 3px solid $primary-color;
                color: $primary-color;
            }
        }
        p {
            padding: 0;
            margin: 0;

            font-size: 20px;
            line-height: 20px;
            text-align: center;

            // color: #ffffff;
        }
    }
    .mobile__icon {
        display: none;
    }
    #lang {
        color: $primary-color;
        // transform: translateY(17px);
    }
    #lang,
    #lang > option {
        margin-left: 82px;
        font-size: 20px;
        text-transform: capitalize;
        // background-color: #000;
        // color: #fff;

        border: none;
        outline: none;
    }
}
.small-nav {
    background-color: rgba($color: #000, $alpha: 0.9);
    box-shadow: 0px 0px 10px rgba($color: #000, $alpha: 0.9);
}

@media (min-width: 1920px) {
    .header {
        .nav__links {
            p,
            select {
                font-size: 20px;
            }
        }
    }
}
@media (max-width: 1250px) {
    .header {
        .logo {
            margin-right: 20px;
        }
        .nav__links {
            p,
            select,
            #lang {
                margin: 0 10px;
                font-size: 12px;
            }
            a {
                margin-left: 2px;
            }
        }
    }
}
@media (max-width: 865px) {
    .header {
        .logo {
            margin-right: 10px;
            img {
                width: 140px;
            }
        }
        .nav__links {
            p,
            select,
            #lang {
                margin: 0 5px;
            }
            a {
                margin-left: 2px;
            }
        }
    }
}
@media (max-width: 768px) {
    .mobile__icon {
        display: flex !important;
        transform: translateY(3px);
        div {
            background-color: #000 !important;
        }
    }
    .header {
        justify-content: space-evenly;
        .logo {
            margin-right: 20%;
        }

        .nav__links {
            position: absolute;
            top: 15%;
            left: 110%;

            width: 100vw;
            min-height: 80vh;
            margin: 0;

            display: flex;
            flex-direction: column;

            background: #f2f2f2;
            border-radius: 12px;

            transition: all 0.5s ease-in-out;
            z-index: 2;
            a {
                width: 100%;
                padding: 10px 0;
            }
            select {
                padding: 10px 0;
            }
        }
    }
}
@media (max-width: 400px) {
    .header {
        .logo {
            margin-right: 0%;
        }
    }
}
