@import "../../variables";

.question-section {

	padding: 80px 0;
	background: #F6F6F6;
	&__title {
		
		font-style: normal;
		font-weight: 700;
		font-size: 48px;
		line-height: 140%;

		color: $main-color;
	}
	&__link {
		text-align: center;

		button {
			padding: 11.5px 34.5px;
			background: $primary-color;
			
			border: none;
			font-size: 20px;
			line-height: 140%;
			color: #ffffff;
			border-radius: 40px;
			box-shadow: 0px 4px 0px 0px #4E256B;
			cursor: pointer;
		}
	}

	.accordion:nth-child(2n){
		background: #FFF;
	}

	#less-img {
		transform: rotate(-90deg);
	}
}

@media screen and (min-width: 1920px) {
	.question-section {
		.section-header__titles {
			margin: 0 30%;
			display: flex;
			justify-content: center;
			margin-bottom: 60px;
		}
		&__link {
			button {
				font-size: 14px;
				line-height: 140%;
			}
		}
	}
}
