@import "../../variables";

.hero {
    position: relative;

    background: url("../../assets/hero-bg.png") center;
    background-size: cover;
    width: 100%;
    height: 100%;

    &::after {
        content: url("../../assets/hero-top-img.png");
        position: absolute;
        right: 0;
        top: 0;
    }

    margin-top: 32px;
    padding: 70px 0 130px;

    &__content {
        position: relative;
        z-index: 0;
        max-width: 55vw;

        padding: 60px 0;
        padding-right: 60px;
        padding-left: 240px;
        // padding-right: 60px;
        background-color: #642887;
        color: #fff;
        &__picture img {
            transform: translateX(-10%);
        }

        &__title {
            font-size: 66px;
            font-weight: 400;
            line-height: 55.6px; /* 50.909% */
            text-transform: uppercase;
        }

        &__price {
            color: #fff;
            font-size: 43.982px;
            font-weight: 700;
            line-height: 61.575px; /* 140% */
        }
        &__text {
            font-size: 24px;
            font-weight: 500;
            line-height: 33.6px; /* 140% */
        }
    }

    &__bg {
        position: absolute;
        top: 0%;
        // width: 80vw;
        right: 0;
        z-index: 1;
        // right: 0;

        img {
            // width: 100vw;
        }
    }

    &__button {
        margin-top: 50px;
        padding: 11.5px 40px;

        position: relative;
        z-index: 5;
        background-color: transparent;
        border: 1px solid transparent;
        border-radius: 30px;

        background-color: #ececec;

        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 140%;
        color: $primary-color;

        transition: background-color 0.3s ease-in-out;
        outline: none;
        cursor: pointer;
    }
    &__button:nth-child(1) {
        margin-right: 24px;
    }
    &__button-active,
    &__button:hover,
    &__button:focus,
    &__button:active {
        border: 1px solid transparent;

        background: #00b48c;
        box-shadow: 0px 4px 0px 0px #009974;
        color: #fff;
    }
    &__button:active:hover {
        background-color: #ececec;
        color: $main-color;
    }
}

@media (min-width: 2500px) {
    .hero {
        padding: 130px 0 300px;
        &__bg {
            right: 0%;
            top: -15%;
        }
    }
}
@media (min-width: 1600px) {
    .hero {
        padding: 130px 0 240px;
        &__bg {
            top: -15%;
            img {
                width: 49vw;
                // width: 100vw;
            }
        }

        &__content{
            max-width: 50vw;
        }
    }
}

@media (min-width: 1201px) and (max-width: 1599px) {
    .hero {
        padding: 130px 0 240px;
        &__bg {
            img {
                width: 46vw;
                // width: 100vw;
            }
        }
        &__content {
            padding-left: 80px;
            padding-right: 10px;

            &__title {
                font-size: 60px;
                font-weight: 400;
                line-height: 55.6px; /* 50.909% */
                text-transform: uppercase;
            }

            &__price {
                color: #fff;
                font-size: 42px;
                font-weight: 700;
                line-height: 61.575px; /* 140% */
            }
            &__text {
                font-size: 14;
                font-weight: 500;
                // line-height: 33.6px; /* 140% */
            }
        }
    }
}

@media (max-width: 1200px) {
    .hero {
        margin-top: 20px;
        &__content {
            text-align: center;
            padding-left: 10px;
            padding-right: 10px;
            max-width: 100%;
            &__picture img {
                transform: translateX(10%);
            }

            &__text {
                font-size: 16px;
                line-height: 140%;
                text-align: center;
            }
        }
        &__buttons {
            text-align: center;
        }
        &__button a,
        &__button {
            font-size: 14px;
            line-height: 140%;
        }
        &__button:nth-child(1) {
            margin-right: 100px;
        }
        &::after {
            content: "";
        }
        &__bg {
            display: none;
            // margin-top: 10px;
            // top: 100%;
            // width: 100%;
            // &-picture {
            //     width: 100%;

            //     img {
            //         width: 100%;
            //         height: auto;
            //         transform: translateX(-10%);
            //     }
            // }
        }
    }
}

@media (max-width: 768px) {
    .hero {
        margin-top: 120px;

        &__buttons {
            display: flex;
            gap: 10px;
            justify-content: center;
        }
        &__content {
            text-align: center;
            padding-left: 10px;
            padding-right: 10px;
            max-width: 100%;
            &__picture img {
                transform: translateX(0);
            }
            &__text {
                font-size: 16px;
                line-height: 140%;
                text-align: center;
            }
        }
        &__button a,
        &__button {
            font-size: 14px;
            line-height: 140%;
        }
        &__button:nth-child(1) {
            margin-right: 0;
        }
        &__bg {
            margin-top: 100px;
            top: 100%;
            width: 100%;
            &-picture {
                width: 100%;

                img {
                    width: 100%;
                    height: auto;
                    transform: translateX(-10%);
                }
            }
        }
    }
}

@media (max-width: 550px) {
    .hero {
        margin-top: 40px;
        &__buttons {
            display: flex;
            // gap: 10px;
            justify-content: center;
            flex-direction: column;
            button {
                margin-top: 20px;
            }
        }
    }
}
