@import "../../variables";

.registration-section {
    .section__title {
        color: #0a0a0a;

        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 67.2px;
        text-align: left;
        margin-left: 98px;
    }
    .section__subtitle {
        color: #0a0a0a;
        margin-left: 98px;
        text-align: left;
        font-size: 14.08px;
        font-style: normal;
        font-weight: 400;
        line-height: 21.12px; /* 150% */
    }

    &__content {
        background: #f6f6f6;
        padding: 42px 51px;
        margin-top: 30px;

        &-item {
            position: relative;
            background: #fff;

            min-height: 432px;
            .content {
                margin-top: 33px;
                max-width: 60%;
            }
            padding: 42px 80px 46px;

            display: flex;
            h3 {
                font-size: 56.895px;
                font-style: normal;
                font-weight: 700;
                line-height: 69.087px; /* 121.429% */
                color: $main-color;
                margin-bottom: 10px;
            }
            p {
                font-size: 16px;
                font-style: normal;
                font-weight: 400;

                color: $main-color;
            }
        }

        &-item::after {
            position: absolute;
            right: 0;
            top: -10%;
        }

        &-item:nth-child(1)::after {
            content: url("../../assets/swiper-1-image.png");
        }
        &-item:nth-child(2)::after {
            content: url("../../assets/swiper-2-image.png");
        }
        &-item:nth-child(3)::after {
            content: url("../../assets/swiper-3-image.png");
        }

        &-item::before {
            content: "";
            background: $primary-color;

            width: 69px;
            height: 69px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            color: #fff;

            font-size: 52.831px;
            font-style: normal;
            font-weight: 700;
            line-height: 69.087px; /* 130.769% */
        }

        &-item:nth-child(1)::before {
            content: "1";
        }
        &-item:nth-child(2)::before {
            content: "2";
        }
        &-item:nth-child(3)::before {
            content: "3";
        }
    }
}

.swiper-button-next {
    &::after {
        content: url("../../assets/swiper-btn-next.png") !important;
    }
}
.swiper-button-prev {
    &::after {
        content: url("../../assets/swiper-btn-prev.png") !important;
    }
}
.swiper-slide{
    height: initial!important;
}

@media only screen and (min-width: 1400px) {
    .registration-section {
        &__content {
            &-item {
                p {
                    font-size: 32.512px;

                    line-height: 52.831px; /* 162.5% */

                    color: $main-color;
                }
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .registration-section {
        &__content {
            &-item {
                .content{
                    max-width: 100%;
                }
                p {
                    font-size: 16px;

                    line-height: 24px; /* 162.5% */

                    color: $main-color;
                }
                h3{
                    font-size: 32px;

                    line-height: 48px; /* 162.5% */
                }
            }
            &-item::after {
                content: none!important;
            }
            
        }
    }
}
@media only screen and (max-width: 600px) {
    .registration-section {
        .section__title {
         
            margin-left: 10px;
        }
        .section__subtitle {
            margin-left: 10px;
        }
        &__content{
            padding: 10px 2px;
        }
    }
}