@import "../../variables";

.gifts {
    // margin-top: 60px;

    .section__title {
        text-align: left;
        margin-left: 98px;
    }

    .cards {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 1px;

        .gift-card {
            position: relative;

            &:nth-child(1) {
                &::after {
                    content: url("../../assets/gift_1.png");
                    position: absolute;
                    right: 0;
                    bottom: 20%;
                }
            }
            &:nth-child(2) {

                &::after {
                    content: url("../../assets/gift_2.png");
                    position: absolute;
                    right: 10%;
                    top: 0;
                }
            }
            &:nth-child(3) {
                &::after {
                    content: url("../../assets/gift_3.png");
                    position: absolute;
                    right: 0;
                    top: 0;
                }
            }
            border: 1.5px solid transparent;

            .content {
                padding: 19px 36px;
            }
            padding-bottom: 0;
            margin-top: 60px;

            border: 1px solid #e5e5e5;
            background: $primary-color;
            display: flex;
            flex-direction: column;

            transition: all 0.3s linear;

            // &:hover,

            &__price {
                margin: 25px 0;

                font-style: normal;
                font-weight: 800;
                font-size: 66px;
                padding: 6px;

                width: fit-content;
                background-color: $secondary-color;
                color: #fff;

                font-size: 66px;
                font-style: normal;
                font-weight: 800;
                line-height: 56px; /* 84.848% */
                letter-spacing: -1.034px;
                // -webkit-background-clip: text;
                // -webkit-text-fill-color: transparent;
                // background-clip: text;
                // text-fill-color: transparent;
            }
            &__title {
                margin-top: 20px;

                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 32px;

                font-feature-settings: "salt" on, "liga" off;

                color: #fff;
            }
            &__subtitle {
                width: 80%;
                // margin: 0 auto;
                margin-top: 8px;

                color: rgba(255, 255, 255, 0.8);
                font-family: "Helvetica";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 150% */
            }

            a {
                flex-grow: 2;
                display: flex;
                justify-content: center;
                align-items: end;
            }
            &__button {
                width: 100%;
                color: $primary-color;
                border-right: 4px solid #fff;
                border-left: 4px solid #fff;
                background: #f6f6f6;
                font-family: "Helvetica";
                font-weight: 500;


                padding: 20px 0;

                span {
                    font-size: 2em;
                    text-align: center;
                    vertical-align: bottom;
                }
            }

            &--active {
                cursor: pointer;
                background: $primary-color;
                .gift-card__price {
                    background: #ff6319;
                }
            }
        }
    }
    .mobile__section {
        picture {
            width: 100%;
            img {
                width: 100%;
            }
        }
    }
}

@media only screen and (min-width: 2600px) {
    .gifts {
        .gift-card {
            // min-height: 550px;
        }
    }
}
// @media screen and (max-width: 2559px) and (min-width: 1920px) {
//     .gifts {
//         .cards {
//             padding: 0 10%;
//             .gift-card {
//                 padding: 28px 20px;
//                 min-height: 600px;
//             }
//         }
//     }
// }

// @media screen and (max-width: 1919px) and (min-width: 1201px) {
//     .gifts {
//         .cards {
//             .gift-card {
//                 min-height: 600px;
//             }
//         }
//     }
// }
// @media screen and (max-width: 1200px) and (min-width: 961px)  {
//     .gifts {
//         margin-top: 50px;

//         .cards {
//             .gift-card {
//                 padding: 28px 0;
//                 min-height: 600px;
//             }
//         }
//     }
// }
// @media screen and (max-width: 960px) {
//     .gifts {
//         margin-top: 50px;

//         .cards {
//             gap: 0px;
//             grid-template-columns: 1fr;
//             .gift-card {
//                 &--active {
//                     transform: scale(1);
//                 }
//             }
//         }
//     }
// }
@media screen and (max-width: 768px) {
    .gifts {
        margin-top: 20px;

        .cards {
            gap: 0px;
            grid-template-columns: 1fr;
            .gift-card {
                &__price {
                    font-size: 38px;
                    line-height: 32px;
                }
                &__title {
                    font-size: 24px;
                    line-height: 32px;
                }
                &__subtitle {
                    font-size: 16px;
                    line-height: 26px;
                }
                &__button {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
        .mobile__section {
            width: 100%;

            picture {
                width: 100%;
                img {
                    width: 100%;
                }
            }
        }
    }
}
