@import "../../variables";

.winner-section {
    padding: 40px 0;

    border-radius: 5px;
    // background: #f8f8f8;

    .winner-bg {
        background: $primary-color;
        padding: 80px 0;
    }
    .winner-table {
        margin-top: 60px;
        z-index: 1;
        table {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            // padding: 0 20px;
            border-radius: 14px;
            border: none;
            // border-spacing: 0 1em;
            // border-collapse: separate;
        }
        tr {
            padding: 0 20px;
            margin: 0 20px;
            margin-bottom: 35px;
            position: relative;

            font-size: 24px;
            // flex-wrap: wrap;

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                // padding: 0 20px;
                // margin: 0 40px;
                height: 2px;
                background: linear-gradient(90deg, $primary-color 0%, rgba(39, 144, 59, 0) 100%);
            }

            .index {
                color: rgba(25, 25, 27, 0.3);
                font-size: 30px;
                font-style: normal;
                font-weight: 400;
                line-height: 70px; /* 233.333% */
            }
        }

        thead {
            tr {
                background: #00b28a;
                color: #fff;
            }
            th {
                border: none;
                color: #fff;
                font-size: 30px;
                font-style: normal;
                font-weight: 500;
                line-height: 70px; /* 233.333% */
            }
        }
        tbody {
            border: none;

            tr:nth-child(2n-1) {
                border-radius: 14px;
                // border: 2px solid rgba(242, 242, 244, 0.6);
                background: #fff;
                box-shadow: 0px 25px 64px 0px rgba(97, 97, 97, 0.07);
            }

            tr:nth-child(2n) {
                background: #F0F0F0;
            }
            // }
            th,
            tr,
            td {
                border-top: none;
            }
        }
    }
}
@media (max-width: 768px) {
    .winner-section {
        .winner-table {
            margin-top: 44px;
            tr,
            th,
            td {
                font-size: 16px !important;
                line-height: 22.4px !important;
                letter-spacing: normal !important;
            }
        }
    }
}
